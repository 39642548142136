/* eslint-disable prettier/prettier */
<template>
  <div>
    <v-btn
      dark
      absolute
      fab
      bottom
      right
      class="accent black--text"
      @click="show_signup = true"
      style="position: fixed; bottom: 25px; right: 25px"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-toolbar elevation="5">
      <v-badge
        :content="table_data.length"
        :value="table_data.length"
        color="red"
        >App Users</v-badge
      >
      <v-btn icon @click="getTableData(tableName)">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-row class="mt-3">
        <v-col col-md-4 col-xs-12>
          <v-menu
            v-model="search.date_menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="search.start_date"
                label="Select start date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.start_date"
              @input="search.date_menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col col-md-4 col-xs-12>
          <v-menu
            v-model="search.date_menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="search.end_date"
                label="Set end date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.end_date"
              @input="search.date_menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-btn
        class="primary white--text ml-1"
        :disabled="!valid"
        raised
        tile
        @click="d"
        >Search
      </v-btn> -->

      <!-- <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>-->
    </v-toolbar>
    <v-progress-linear
      indeterminate
      color="pink"
      height="4"
      v-show="showProgress"
    ></v-progress-linear>
    <div>
      <ejs-grid
        style="border-style: none"
        width="100%"
        ref="grid"
        gridLines="horizontal"
        :dataSource="table_data"
        :rowHeight="40"
        :allowPaging="true"
        :allowSorting="true"
        :pageSettings="pageSettings"
        :selectionSettings="selectionSettings"
        :allowFiltering="true"
        :filterSettings="filterOptions"
        :actionComplete="actionComplete"
        :actionBegin="actionBegin"
        :allowSelection="true"
        :contextMenuItems="contextMenuItems"
        :editSettings="editSettings"
        :showColumnMenu="true"
        :toolbar="toolbar"
        :showColumnChooser="true"
        :allowGrouping="false"
        :allowExcelExport="true"
        :allowPdfExport="true"
        :allowReordering="true"
        :allowResizing="true"
        :rowSelected="onRowSelected"
        :toolbarClick="toolbarClick"
      >
        <e-columns>
          <e-column
            type="checkbox"
            :allowFiltering="false"
            :allowSorting="false"
            :allowEditing="false"
            width="40"
          ></e-column>
          <!-- <e-column
            field="id"
            headerText="User Id"
            width="150"
          ></e-column> -->
           <e-column
            field="firstName"
            headerText="First Name"
            width="150"
            format="C2"
            textAlign="left"
          ></e-column>
           <e-column
            field="lastName"
            headerText="Last Name"
            width="150"
            format="C2"
            textAlign="left"
          ></e-column>
          <e-column
            field="email"
            headerText="Username"
            width="150"
            format="C2"
            textAlign="left"
          ></e-column>
          <e-column
            field="requests"
            headerText="No of Request"
            width="150"
            textAlign="left"
          ></e-column>
           <e-column
            field="plans"
            headerText="No of Plan"
            width="150"
            textAlign="left"
          ></e-column>
          <!-- <e-column
            field="created"
            headerText="Last Job Created on"
            width="150"
            textAlign="left"
          ></e-column> -->
        </e-columns>
          <e-column
            field="phone"
            headerText="Phone Number"
            width="150"
            format="C2"
            textAlign="left"
          ></e-column>
           <e-column
            field="roleName"
            headerText="Role"
            width="150"
            textAlign="Left"
          ></e-column>        
      </ejs-grid>
    </div>

    <v-dialog v-model="dialog" max-width="550px">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          Edit Records
          <v-icon color="red">mdi-arrow-right</v-icon>
          <v-icon x-small>mdi-file</v-icon>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text> <v-icon>cancel</v-icon>Cancel </v-btn>
        <v-btn text> <v-icon>done</v-icon>Save </v-btn>
      </v-toolbar>
      <v-card>
        <!--<div class="text-center blue darken-2 dark white--text pa-3" elevation="1">
          <h3>
            <v-icon x-small color="white">mdi-database</v-icon>
            {{connection.database}} /
            <v-icon x-small color="white">mdi-table</v-icon>
            {{ table.tableName }}
            <small>
              (
              <v-icon x-small color="white">mdi-key</v-icon>
              Primary Key = {{ table.primaryKey }})
            </small>
          </h3>
          <h4></h4>
          <small>supply the fields values and click save</small>
        </div>-->
        <v-card-text class="black--text" style="height: 300px; overflow: auto">
          <v-container>
            <v-text-field
              v-for="(item, value) in selection"
              type="text"
              :key="value"
              :v-model="selection"
              :label="value"
            >
              <v-icon slot="prepend" color small>mdi-pencil</v-icon>
            </v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveEdits(selection)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showProgress" hide-overlay persistent width="400">
      <v-card color="warn">
        <v-card-text class="white pa-2">
          Connecting to your database, please wait...
          <v-progress-linear
            indeterminate
            color="primary"
            class="ma-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="
              showDialog = true;
              alert.alertDialog = false;
            "
            >Retry</v-btn
          >
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show_signup" max-width="600">
      <v-card>
        <v-card-title class="title"> Register New User </v-card-title>
        <sign-up />
      </v-card>
    </v-dialog>

    <v-snackbar
      content-class="black--text"
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.bgcolor"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
    >
      {{ alert.text }}
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import SignUp from "./SignUp.vue";
import {
  GridPlugin,
  Filter,
  Page,
  Selection,
  Sort,
  Resize,
  ColumnMenu,
  PdfExport,
  ExcelExport,
  Toolbar,
  Reorder,
  Group,
  ColumnChooser,
  ContextMenu,
  Edit,
  CommandColumn,
  ForeignKey,
  Freeze,
} from "@syncfusion/ej2-vue-grids";
// import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
import Api from "../app.settings.network";
import AppSettings from "../app.settings";
Vue.use(GridPlugin);

const apiUrl = AppSettings.API_ENDPOINT;
export default Vue.extend({
  props: {},
  components: {
    SignUp,
  },
  data: () => {
    return {
      show_signup: false,
      month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      search: {
        date_menu1: false,
        date_menu2: false,
        surcon: "",
        year: "",
        quarter: "",
        month: "",
        day: "",
        start_date: "",
        end_date: "",
      },
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      tab: null,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Database Conection",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 4000,
        x: "right",
        y: "bottom",
      },
      dialog: false,
      showProgress: true,
      selRecord: {},
      height: "100%",
      allowPaging: true,
      pageSettings: { pageSizes: [12, 50, 100, 200, 500], pageCount: 10 },
      serverPageSettings: {
        size: 500,
        skip: 0,
        pageno: 1,
      },
      filterOptions: {
        type: "Menu",
      },
      filter: {
        type: "CheckBox",
      },
      selectionSettings: {
        persistSelection: true,
        type: "Multiple",
        checkboxOnly: true,
      },
      toolbar: [
        // "Delete",
        "Update",
        "Cancel",
        "Search",
        "ColumnChooser",
        "ExcelExport",
        "PdfExport",
        "CsvExport",
      ],
      contextMenuItems: [
        "AutoFit",
        "AutoFitAll",
        "SortAscending",
        "SortDescending",
        "Copy",
        "Edit",
        "Delete",
        "Save",
        "Cancel",
        "PdfExport",
        "ExcelExport",
        "CsvExport",
        "FirstPage",
        "PrevPage",
        "LastPage",
        "NextPage",
      ],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        mode: "Dialog",
      },
      editOptions: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        mode: "Dialog", //"Normal" 'Batch'
      },
      commands: [
        {
          type: "Edit",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-edit e-icons",
          },
        },
        {
          type: "Delete",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-delete e-icons",
          },
        },
        {
          type: "Save",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-update e-icons",
          },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
      table1: {
        tableName: "",
        settings: {},
        primaryKey: "",
        rows: [],
        columns: [],
        clientColumns: [],
      },
      tabledata1: [],
      table_data: [],
      tableName: "",
    };
  },
  filters: {
    subStr: function (value) {
      if (value !== undefined) {
        return "https://adamscollege.org" + value.substring(2);
      }
    },
  },
  computed: {
    connection: function () {
      return this.$store.getters.getConnection;
    },
    tables() {
      let req = this.$store.getters.getTables;
      return req;
    },
    progressStatus() {
      return this.$store.getters.getProgressStatus;
    },
  },
  methods: {
    getTableData () {
      this.tableName = "users";
      const vm = this;
      this.showProgress = true;
      Api()
        .get("api/users")
        .then((response) => {
          vm.showProgress = false;
          console.log(response.data);
          return (this.table_data = response.data);
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    UpdateJSON(jsonArray, item) {
      for (var i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].RegistrationID == item.RegistrationID) {
          jsonArray[i] = item;
          return jsonArray;
        }
      }
    },
    saveEdits(item) {
      let i;
      let pKeyValue;
      for (i = 0; i < item.length; i++) {
        if (item[i].field == this.table.primaryKey) {
          pKeyValue = item[i].value;
        }
      }
      // for (i = 0; i < item.length; i++) {
      //editData = editData + item[i].field + ":" + item[i].value + "";
      let editData = "{ ";
      item.forEach(function (col, index) {
        if (index == item.length - 1) {
          editData = editData + '"' + col.field + '":"' + col.value + '" }';
        } else {
          editData = editData + '"' + col.field + '":"' + col.value + '",';
        }
      });
      editData = JSON.parse(editData);

      let connection = this.connection;
      connection.editData = editData;
      connection.pKeyValue = pKeyValue;
      connection.primaryKey = this.table.primaryKey;
      connection.pKeyField = this.table.primaryKey;
    },
    delete(data) {
      const vm = this;
      this.showProgress = true;
      //alert(JSON.stringify(data));
      try {
        Api()
          .post(apiUrl + "api/table/delete", data)
          .then((response) => {
            vm.alert.text = response.data.message;
            vm.alert.title = "Delete Record";
            vm.showProgress = false;
            vm.alert.bgcolor = "primary";
            //vm.alert.alertDialog = true;
            vm.alert.snackbar = true;
            vm.showDialog = false;
          })
          .catch(function (ex) {
            //alert(JSON.stringify(ex))
            vm.alert.title = "Delete Record Failed";
            if (ex.response == undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"),
          (this.alert.text = e.message),
          (this.alert.title = "Table Records")
        );
      }
    },
    update(data) {
      const vm = this;
      this.showProgress = true;
      //alert(JSON.stringify(data));
      try {
        Api()
          .put(apiUrl + "api/table/update/" + data.pKeyValue, data)
          .then((response) => {
            vm.alert.text =
              "Record with " +
              data.primaryKey +
              " = " +
              data.pKeyValue +
              " was updated successfully";
            vm.alert.title = "Table Record Update";
            vm.showProgress = false;
            vm.alert.bgcolor = "accent";
            //vm.alert.alertDialog = true;
            vm.alert.snackbar = true;
            vm.showDialog = false;
            return response.data.message;
          })
          .catch(function (ex) {
            if (ex.response != undefined) {
              vm.alert.title = "Record Update Failed";
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"),
          (this.alert.text = e.message),
          (this.alert.title = "Table Records")
        );
      }
    },
    insert(insertData) {
      const vm = this;
      this.showProgress = true;
      //alert(JSON.stringify(insertData));
      try {
        Api()
          .post(apiUrl + "api/table/insert", insertData)
          .then((response) => {
            vm.alert.text = response.message;
            return (
              (vm.showProgress = false),
              //(vm.alert.alertDialog = true),
              (vm.alert.snackbar = true),
              (vm.alert.bgcolor = "primary"),
              (vm.alert.text = "Record was inserted successfully"),
              (vm.showDialog = false)
            );
          })
          .catch(function (ex) {
            if (ex.response != undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"),
          (this.alert.text = e.message),
          (this.alert.title = "Table Records")
        );
      }
    },
    toolbarClick: function (args) {
      switch (args.item.text) {
        case "PDF Export":
          this.$refs.grid.pdfExport();
          break;
        case "Excel Export":
          this.$refs.grid.excelExport();
          break;
        case "CSV Export":
          this.$refs.grid.csvExport();
          break;
      }
    },
    exportToPDF() {
      this.$refs.grid.pdfExport();
    },
    exportToExcel() {
      this.$refs.grid.excelExport();
    },
    pushSelectedRow(item) {
      const payload = { record: item, tablename: this.tableoptions.tablename };
      this.$store.commit("setSelectedRow", payload);
    },
    onRowSelected: function (args) {
      //alert(JSON.stringify(args.data));
      if (
        args.requestType !== "beginEdit" ||
        args.requestType !== "add" ||
        args.requestType !== "delete"
      ) {
        const record = args.data;
        //this.dialog = true;
        //let columns = this.table.columns;
        //alert(JSON.stringify(record));
        let fields = [];
        let i = 0;
        let f;
        for (const key in record) {
          if (key == this.table.primaryKey) {
            //alert(key);
            f = {
              field: key,
              value: record[key],
              primaryKey: true,
              readOnly: true,
              disabled: true,
              type: "text",
              icon: "mdi-key",
            };
            fields[i] = f;
          } else {
            f = {
              field: key,
              value: record[key],
              primaryKey: false,
              readOnly: false,
              disabled: false,
              //required : isNullable,
              icon: "mdi-pencil",
              type: "text",
              editType: "datepickeredit or dropdownedit or numericedit",
            };
            fields[i] = f;
          }
          i++;
        }
        this.selRecord = fields;
        this.selection = args.data;
      }
    },
    actionComplete(args) {
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        let dialog = args.dialog;
        if (window.innerWidth < 400) {
          dialog.height = 80 + "%";
          dialog.width = 85 + "%";
        } else {
          dialog.height = 70 + "%";
          dialog.width = 60 + "%";
        }

        // change the header of the dialog
        dialog.header =
          args.requestType === "beginEdit"
            ? "Record of " + args.rowData[this.table.primaryKey]
            : "New " + this.table.tableName;
      }
    },
    actionBegin: function (args) {
      if (args.requestType == "save") {
        if (args.action == "edit") {
          let connection = this.connection;
          connection.pKeyValue = args.data[this.table.primaryKey];
          connection.primaryKey = this.table.primaryKey;
          connection.pKeyField = this.table.primaryKey;
          const editData = deleteUneditableColumns(
            args.data,
            this.table.columns
          );
          connection.editData = editData;
          //alert(JSON.stringify(editData))
          this.update(connection);
        }
        if (args.action == "add") {
          let connection = this.connection;
          const insertData = deleteUneditableColumns(
            args.data,
            this.table.columns
          );
          connection.editData = insertData;
          connection.insertData = insertData;

          this.insert(connection);
        }
      }
      if (args.requestType == "delete") {
        let connection = this.connection;
        const selectedDelRecord = args.data[0];
        connection.pKeyValue = selectedDelRecord[this.table.primaryKey];
        connection.pKeyField = this.table.primaryKey;
        this.delete(connection);
      }
    },
    encriptPass(plainPassword) {
      const a = this.CryptoJS.AES.encrypt(
        plainPassword,
        localStorage.getItem("userName")
      ).toString();
      //alert(a);
      return a;
    },
    decriptPass(encriptedPassword) {
      const a = this.CryptoJS.AES.decrypt(
        encriptedPassword,
        localStorage.getItem("userName")
      ).toString(this.CryptoJS.enc.Utf8);
      //alert(a);
      return a;
    },
  },
  watch: {
    table() {
      this.sheet = false;
      //alert(this.connection.primaryKey)
      if (!this.connection.primaryKey) {
        //alert(this.editSettings.allowEditing)
        return (this.editSettings.allowEditing = false);
      }
    },
    selectedQuery(query) {
      return (
        (this.code = query.statement), (this.queryName = query.description)
      );
    },
    queryName(queryName) {
      if (queryName.length > 0 && this.code.length > 5) {
        return (this.isQueryNameExist = true);
      } else {
        return (this.isQueryNameExist = false);
      }
    },
  },
  provide: {
    grid: [
      Filter,
      Page,
      Selection,
      Sort,
      Resize,
      ColumnMenu,
      Reorder,
      PdfExport,
      ExcelExport,
      Toolbar,
      Group,
      ContextMenu,
      Edit,
      CommandColumn,
      ColumnChooser,
      ForeignKey,
      Freeze,
    ],
  },
  mounted() {
    this.editor.focus();
    console.log("this is current editor object", this.editor);
  },
  created() {
    this.showProgress = false;
    this.showDialog = false;
    //this.$store.commit("loadSqlQueries")
    this.getTableData();
  },
});
</script>
<style scoped>
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
.e-grid .e-altrow {
  background-color: whitesmoke;
}
/* CodeMirror */
.remove-padding {
  margin-left: -18px;
  margin-top: -15px;
  margin-right: -17px;
  margin-bottom: -15px;
  z-index: 0;
}
.CodeMirror-hints,
.CodeMirror-hint,
.CodeMirror-lint-tooltip .CodeMirror-hint-active {
  z-index: 2147483647 !important;
  position: relative !important;
  background: white !important;
}
</style>
