<template>
  <div id="app">
    <v-app id="vapp" style="background-color: #eaf2f8">
      <!-- <div > -->
      <v-app-bar
        v-if="isLoggedIn"
        app
        flat class="primary darken-2"
        clipped-left dark
        collapse-on-scroll
        dense
        ref="topbar"
        elevation="1"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <img width="40" aspect-ratio="1" src="./assets/logo.png" />
        <v-toolbar-title class="ml-0 pl-3">
          <span class="hidden-xs-and-down"
          >Pillar Number Management System(v1.0)</span
          >
        </v-toolbar-title>
        <!-- <v-breadcrumbs :items="items" class="dense hidden-xs-only"></v-breadcrumbs> -->

        <v-spacer></v-spacer>
        <v-btn
          v-show="isLoggedIn"
          class="hidden-xs-only hidden-sm-only"
          text
          style="
            text-transform: lowercase;
            display: inline-block;
            font-family: sans-serif;
          "
        >
          <v-icon>person</v-icon>{{ userName }}
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer
        v-if="isLoggedIn"
        clipped
        app
        flat
        mini-variant
        mini-variant-width="75"
        expand-on-hover
        v-model="drawer"
        width="300"
        elevation="5"
      >
        <div
          class="text-center primary hidden-lg-only pt-4"
          max-width="375"
          max-height="250"
          elevation="0"
          style="padding: 5px"
        >
          <v-avatar color="grey lighten-3" size="60">
            <v-icon>mdi-account</v-icon>
          </v-avatar>
          <!-- <h3 class="text-center">Damilola Kolawole</h3> -->
          <p class="text-center subTitle mt-2">{{ userName }}</p>
        </div>
        <!-- <v-list class="ma-2">
          <v-list-item
            @click="SignOut(item)"
            class="bottom-line"
            v-for="item in items"
            :key="item.title">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
        <v-list>
          <v-list-group
            v-show="item.items"
            class="bottom-line-white"
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="subItem in item.items"
              :key="subItem.title"
              :to="subItem.component"
            >
              <v-list-item-icon>
                <v-icon>{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-show="!item.items && item.roles.includes(userRole)"
            class="bottom-line"
            v-for="item in items"
            :to="item.component"
            :key="item.title"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="SignOut()" class="bottom-line">
            <v-list-item-icon>
              <v-icon>power_settings_new</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <!-- style="background-color:#EAF2F8" -->
      <v-main class="ma-2">
        <router-view></router-view>
        <!-- </v-container> -->
      </v-main>
      <!-- <v-btn fab bottom right color="red" dark fixed @click.stop="dialog = !dialog">
        <v-icon>mdi-plus</v-icon>
      </v-btn>-->
      <bottom-bar v-show="isLoggedIn" class="hidden-xs-only" />

      <!-- </div> -->
      <!-- <div v-else>
      <Login/>
      </div>-->
    </v-app>
  </div>
</template>
<script>
//  @ is an alias to /src
// import TopBar from '@/components/TopBar.vue'
import BottomBar from "@/components/Footer.vue";
//import Login from "./views/Login";
//import router from "./router";
// import SideBar from '@/components/SideBar.vue'
//  import AppBar from '@/components/AppBar.vue'

export default {
  name: "Home",
  components: {
    // AppBar,
    // TopBar,
    BottomBar,
    // Login
    // SideBar
  },
  props: {
    source: String,
  },
  data() {
    return {
      colors: ["primary", "blue", "success", "red", "teal"],
      permanent: true,
      miniVariant: true,
      expandOnHover: true,
      background: false,
      dialog: false,
      color: "blue",
      drawer: false,
      left: false,
      items: [
        {
          title: "Home",
          icon: "mdi-home",
          component: "/",
          text: "Home",
          disabled: false,
          roles: ["Creator", "Editor", "Viewer","Admin", "Security Admin", "System Admin", 'Super Admin'],
        },
        {
          title: "Registered Pillars",
          icon: "pin_drop",
          component: "request",
          text: "List of pillar requests",
         roles: ['Super Admin',"Creator", "Editor", "Viewer","Admin", "Security Admin", "System Admin"],
        },
        // {
        //   title: "Income",
        //   icon: "mdi-finance",
        //   component: "income",
        //   text: "List of Income/Revenues",
        // },        
        {
          title: "Surveyors",
          icon: "supervisor_account",
          component: "surveyor",
          text: "List of Practising Surveyors",
          roles: ['Super Admin',"Creator", "Editor", "Viewer","Admin", "Security Admin", "System Admin"],
        },
        {
          title: "Users",
          icon: "supervisor_account",
          component: "users",
          text: "Manage Users Account",
          disabled: false,
          roles: ['Super Admin',"Security Admin", "System Admin"],
        },
        {
          title: "Change Password",
          icon: "lock",
          text: "Change Password",
          component: "ChangePassword",
          disabled: false,
          roles: ["Super Admin","Creator", "Editor", "Viewer","Admin", "Security Admin", "System Admin"],
        },
        //{ title: "Help", icon: "help", text: "Help" },
        // {
        //   title: "Sign Out",
        //   icon: "power_settings_new",
        //   component: "Login",
        // },
      ],
      admins: [
        ["Management", "people_outline"],
        ["Settings", "settings"],
      ],
      cruds: [
        ["Create", "add"],
        ["Read", "insert_drive_file"],
        ["Update", "update"],
        ["Delete", "delete"],
      ],
    };
  },
  computed: {
    userRole() {
      return localStorage.getItem("role");
    },
    connection: function () {
      return this.$store.getters.getConnection;
    },
    userName: function () {
      return this.$store.getters.getUserName;
    },
    isConnected: function () {
      const conn = this.$store.getters.getConnection;
      if (conn.database == undefined || conn.database == null) {
        return false;
      }
      return true;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    SignOut: function () {
      this.$store.dispatch("logout");
      //location.reload()
    },
    load(item) {
      if (item.title == "Sign Out") {
        this.$store.commit("logout");
      }
      alert("out");
      return "go";
    },
  },
  created() {
    //router.replace('Login')
    this.$store.dispatch("tryAutoLogin");
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.map-div {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.navigation-drawer__border {
  //width: 300px!important;
  //background-color: red!important;
  border-style: none;
  border-block-color: white;
}
.centre {
  text-align: center;
}
.bottom-line {
  border-bottom: solid thin rgb(226, 226, 226);
}
.bottom-line-white {
  border-bottom: solid thin rgb(255, 255, 255);
}
</style>
