import Vue from 'vue'
import VueRouter from 'vue-router'
import Request from '../views/PillarRequest.vue'
import Dues from '../views/Dues.vue'
import Surveyor from '../views/Surveyors.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ChangePassword from '../views/ChangePassword.vue'
import ForgetPassword from '../views/RecorverPassword.vue'
import Users from '../views/User.vue'
//import ChangePassword from '../views/ChangePassword.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: { requiresAuth: true, roles: ["Admin", "Security Admin", "Super Admin"] }
  },
  {
    path: '/request',
    name: 'request',
    component: Request,
    meta: { requiresAuth: true, roles: ["Creator", "Editor", "Viewer","Admin", "Security Admin", "Super Admin"] }
  },
  {
    path: '/surveyor',
    name: 'surveyor',
    component: Surveyor,
    meta: { requiresAuth: true, roles: ["Creator", "Editor", "Viewer","Admin", "Security Admin", "Super Admin"] }
  },
  {
    path: '/Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiresAuth: true }
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword
  }
]

const router = new VueRouter({
  mode: "hash",
  routes
})

//import store from '../store'
//const isloggedin = store.getters.isAuthenticated;
// const role = localStorage.getItem("role")
// router.beforeEach((to, from, next) => {
//   // if (to.name !== 'login' && !isloggedin) {
//   //   next({
//   //     path: 'login',
//   //     replace: true
//   //   })
//   // }
//   const lacksRole = to.matched.some(route => {
//     return route.meta.roles && !route.meta.roles.includes(role)
//   })
//   if (lacksRole) {
//     return next(false)  // Aborts the navigation request
//   }
//   // Route either doesn't require any role or user has one of the 
//   // roles specified in the route meta object, continue navigation
//   next()
//})
export default router
