/* eslint-disable prettier/prettier */
<template>
  <div>
    <v-btn
      dark
      absolute
      fab
      bottom
      right
      class="accent black--text"
      @click="showDialog = true"
      style="position: fixed; bottom: 25px; right: 25px"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-toolbar elevation="5">
      <v-badge
        :content="data.length"
        :value="data.length"
        color="red"
        >Surveyor Details</v-badge
      >
      <v-btn icon @click="getTableData()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-row class="mt-3">
        <v-col col-md-4 col-xs-12>
          <v-menu
            v-model="search.date_menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="search.start_date"
                label="Select start date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.start_date"
              @input="search.date_menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col col-md-4 col-xs-12>
          <v-menu
            v-model="search.date_menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="search.end_date"
                label="Set end date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.end_date"
              @input="search.date_menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row> -->
      <v-btn
        class="primary white--text ml-1"
        :disabled="!valid"
        raised
        tile
        @click="d"
        >Search
      </v-btn>

      <!-- <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>-->
    </v-toolbar>
    <v-progress-linear
      indeterminate
      color="pink"
      height="4"
      v-show="showProgress"
    ></v-progress-linear>
    <div>
      <ejs-grid
        style="border-style: none"
        width="100%"
        ref="grid"
        gridLines="horizontal"
        :dataSource="data"
        :rowHeight="40"
        :allowPaging="true"
        :allowSorting="true"
        :pageSettings="pageSettings"
        :selectionSettings="selectionSettings"
        :allowFiltering="true"
        :filterSettings="filterOptions"
        :actionComplete="actionComplete"
        :actionBegin="actionBegin"
        :allowSelection="true"
        :contextMenuItems="contextMenuItems"
        :editSettings="editSettings"
        :showColumnMenu="true"
        :toolbar="toolbar"
        :showColumnChooser="true"
        :allowGrouping="false"
        :allowExcelExport="true"
        :allowPdfExport="true"
        :allowReordering="true"
        :allowResizing="true"
        :rowSelected="onRowSelected"
        :toolbarClick="toolbarClick"
      >
        <e-columns>
          <e-column
            type="checkbox"
            :allowFiltering="false"
            :allowSorting="false"
            :allowEditing="false"
            width="40"
          ></e-column>
          <e-column
            field="id"
            headerText="Surcon"
            allowEditing=false
            width="100"            
            isPrimaryKey = "true"
            textAlign = "Left"
            type= "int">
         </e-column>
         <e-column
            field="name"
            headerText="Fullname"
            allowEditing=false
            width="150"
            textAlign = "Left"
            type= "int">
         </e-column>
            <e-column field="pillarSuffix" 
            headerText="Pillar Suffix" allowEditing="false" width="150"></e-column>
       <e-column
            field="planPrefix"
            headerText="Plan Prefix"
            allowEditing=false
            width="150"
            textAlign="Left"
          ></e-column>
          <e-column
            field="company"
            headerText="Practise Name"
            allowEditing=false
            width="150"
            textAlign="Right"
          ></e-column>
          <e-column
            field="email"
            headerText="Email"
            allowEditing=false
            width="150"
            textAlign="Left"
          ></e-column>
           <e-column
            field="address"
            headerText="Address"
            allowEditing=false
            width="150"
            textAlign="Left"
          ></e-column>
           <e-column
            field="cadre"
            headerText="Cadre"
            allowEditing=false
            width="150"
            textAlign="Left"
          ></e-column> 
            </e-columns>
        <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="amount" format="N2" :footerTemplate="sumTemplate" >
                        </e-column>
                    </e-columns>
                </e-aggregate>
                <!-- <e-aggregate>
                    <e-columns>
                        <e-column type="Average" field="amount" format="C2" :footerTemplate="avgTemplate">
                        </e-column>
                    </e-columns>
                </e-aggregate> -->
            </e-aggregates>
      </ejs-grid>
    </div>
    <v-dialog v-model="showProgress" hide-overlay persistent width="400">
      <v-card color="warn">
        <v-card-text class="white pa-2">
          Connecting to your database, please wait...
          <v-progress-linear
            indeterminate
            color="primary"
            class="ma-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="
              showDialog = true;
              alert.alertDialog = false;
            "
            >Retry</v-btn
          >
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      content-class="black--text"
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.bgcolor"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
    >
      {{ alert.text }}
    </v-snackbar>
  
  </div>
</template>

<script>
import Vue from "vue";
import {
  GridPlugin,
  Filter,
  Page,
  Selection,
  Sort,
  Resize,
  ColumnMenu,
  PdfExport,
  ExcelExport,
  Toolbar,
  Reorder,
  Group,
  ColumnChooser,
  ContextMenu,
  Edit,
  CommandColumn,
  ForeignKey,
  Freeze,
  Aggregate,
} from "@syncfusion/ej2-vue-grids";
// import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
import Api from "../app.settings.network";
import AppSettings from "../app.settings";
Vue.use(GridPlugin);

const apiUrl = AppSettings.API_ENDPOINT;
export default Vue.extend({
  props: {},
  data: () => {
    return {
      month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      search: {
        date_menu1: false,
        date_menu2: false,
        surcon: "",
        year: "",
        quarter: "",
        month: "",
        day: "",
        start_date: "",
        end_date: "",
      },
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      tab: null,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Database Conection",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 4000,
        x: "right",
        y: "bottom",
      },
      dialog: false,
      showProgress: true,
      selRecord: {},
      height: "100%",
      allowPaging: true,
      pageSettings: { pageSize: 8, pageSizes: [12, 50, 100, 200, 500], pageCount: 10 },
      serverPageSettings: {
        size: 500,
        skip: 0,
        pageno: 1,
      },
      filterOptions: {
        type: "Menu",
      },
      filter: {
        type: "CheckBox",
      },
      selectionSettings: {
        persistSelection: true,
        type: "Multiple",
        checkboxOnly: true,
      },
      toolbar: [
        "Update",
        "Cancel",
        "Search",
        "ColumnChooser",
        "ExcelExport",
        "PdfExport",
        "CsvExport",
      ],
      contextMenuItems: [
        "AutoFit",
        "AutoFitAll",
        "SortAscending",
        "SortDescending",
        "Copy",
        "Edit",
        "Delete",
        "Save",
        "Cancel",
        "PdfExport",
        "ExcelExport",
        "CsvExport",
        "FirstPage",
        "PrevPage",
        "LastPage",
        "NextPage",
      ],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        mode: "Dialog",
      },
      editOptions: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        mode: "Dialog", //"Normal" 'Batch'
      },
      groupSettings: { showDropArea: true },
      sumTemplate: function() {
        return {
            template: Vue.component('sumTemplate', {
            template: `<h3 class="red--text my-2 mr-3">{{data.Sum}}</h3>`,
            data: function () {return {data: {data: {}}};}
            })
        }
      },
      avgTemplate: function() {
        return {
            template : Vue.component('avgTemplate', {
            template: `<span>Average: {{data.Average}}</span>`, 
            data: function () {return { data: {data: {}}};}
            })
        }
      },
      commands: [
        {
          type: "Edit",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-edit e-icons",
          },
        },
        {
          type: "Delete",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-delete e-icons",
          },
        },
        {
          type: "Save",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-update e-icons",
          },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
      table1: {
        tableName: "",
        settings: {},
        primaryKey: "",
        rows: [],
        columns: [],
        clientColumns: [],
      },
      tabledata1: [],
      table_data: [],
      data: [],
      tableName: "",
    };
  },
  filters: {
    subStr: function (value) {
      if (value !== undefined) {
        return "https://adamscollege.org" + value.substring(2);
      }
    },
  },
  computed: {
    connection: function () {
      return this.$store.getters.getConnection;
    },
    tables() {
      let req = this.$store.getters.getTables;
      return req;
    },
    progressStatus() {
      return this.$store.getters.getProgressStatus;
    },
    table() {
      let req = this.$store.getters.getTable;
      const columns = getFields(req);
      //req.columns = columns;
      req.clientColumns = columns;
      return req;
    },
  },
  methods: {
    getTableData: function (table_name) {
      const vm = this;
      this.showProgress = true;
      this.tableName = table_name;
      Api()
        .get("api/surveyor")
        .then((response) => {
          vm.showProgress = false;
          //console.log(response.data);
          return (this.data = response.data);
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    UpdateJSON(jsonArray, item) {
      for (var i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].RegistrationID == item.RegistrationID) {
          jsonArray[i] = item;
          return jsonArray;
        }
      }
    },
    saveEdits(item) {
      let i;
      let pKeyValue;
      for (i = 0; i < item.length; i++) {
        if (item[i].field == this.table.primaryKey) {
          pKeyValue = item[i].value;
        }
      }
      // for (i = 0; i < item.length; i++) {
      //editData = editData + item[i].field + ":" + item[i].value + "";
      let editData = "{ ";
      item.forEach(function (col, index) {
        if (index == item.length - 1) {
          editData = editData + '"' + col.field + '":"' + col.value + '" }';
        } else {
          editData = editData + '"' + col.field + '":"' + col.value + '",';
        }
      });
      editData = JSON.parse(editData);

      let connection = this.connection;
      connection.editData = editData;
      connection.pKeyValue = pKeyValue;
      connection.primaryKey = this.table.primaryKey;
      connection.pKeyField = this.table.primaryKey;
    },  
    toolbarClick: function (args) {
      switch (args.item.text) {
        case "PDF Export":
          this.$refs.grid.pdfExport();
          break;
        case "Excel Export":
          this.$refs.grid.excelExport();
          break;
        case "CSV Export":
          this.$refs.grid.csvExport();
          break;
      }
    },
    exportToPDF() {
      this.$refs.grid.pdfExport();
    },
    exportToExcel() {
      this.$refs.grid.excelExport();
    },
    getTableRecords() {
      this.showProgress = true;
      const vm = this;
      try {
        Api()
          .put(
            apiUrl + "api/table/" + this.connection.tableName,
            this.connection
          )
          .then((response) => {
            this.$store.commit("setTable", response.data);
            return (this.showProgress = false), (this.showDialog = false);
          })
          .catch(function (ex) {
            if (ex.response != undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"),
          (this.alert.text = e.message),
          (this.alert.title = "Table Records")
        );
      }
    },
    encriptPass(plainPassword) {
      const a = this.CryptoJS.AES.encrypt(
        plainPassword,
        localStorage.getItem("userName")
      ).toString();
      //alert(a);
      return a;
    },
    decriptPass(encriptedPassword) {
      const a = this.CryptoJS.AES.decrypt(
        encriptedPassword,
        localStorage.getItem("userName")
      ).toString(this.CryptoJS.enc.Utf8);
      //alert(a);
      return a;
    },
  },
  watch: {
    table() {
      this.sheet = false;
      //alert(this.connection.primaryKey)
      if (!this.connection.primaryKey) {
        //alert(this.editSettings.allowEditing)
        return (this.editSettings.allowEditing = false);
      }
    },
    selectedQuery(query) {
      return (
        (this.code = query.statement), (this.queryName = query.description)
      );
    },
    queryName(queryName) {
      if (queryName.length > 0 && this.code.length > 5) {
        return (this.isQueryNameExist = true);
      } else {
        return (this.isQueryNameExist = false);
      }
    },
  },
  provide: {
    grid: [
      Filter,
      Page,
      Selection,
      Sort,
      Resize,
      ColumnMenu,
      Reorder,
      PdfExport,
      ExcelExport,
      Toolbar,
      Group,
      ContextMenu,
      Edit,
      CommandColumn,
      ColumnChooser,
      ForeignKey,
      Freeze,
      Aggregate,
    ],
  },
  mounted() {
    this.editor.focus();
    console.log("this is current editor object", this.editor);
  },
  created() {
    this.showProgress = false;
    this.showDialog = false;
    //this.$store.commit("loadSqlQueries")
    this.getTableData("account_transactions_details");
  },
});
</script>
<style scoped>
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
.e-grid .e-altrow {
  background-color: whitesmoke;
}
/* CodeMirror */
.remove-padding {
  margin-left: -18px;
  margin-top: -15px;
  margin-right: -17px;
  margin-bottom: -15px;
  z-index: 0;
}
.CodeMirror-hints,
.CodeMirror-hint,
.CodeMirror-lint-tooltip .CodeMirror-hint-active {
  z-index: 2147483647 !important;
  position: relative !important;
  background: white !important;
}
</style>
