export default {
  API_ENDPOINT:  "https://ssce.flexiblebase.com/", 
  //API_ENDPOINT: "https://localhost:7037/",
  //API_ENDPOINT: "https://localhost:7037/",
  API_AUTH_URL: "https://localhost:5001/",
  APP_THEME: {},
  TABLE_SETTINGS: {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    mode: "Dialog", //"Normal" //'Batch',
    allowPaging: "true",
    allowSorting: "true",
    pageSettings: "pageSettings",
    selectionSettings: "selectionSettings",
    allowFiltering: "true",
    filterSettings: "filterOptions",
    actionComplete: "actionComplete",
    actionBegin: "actionBegin",
    allowSelection: "true",
    editSettings: "editing",
    showColumnMenu: "true",
    showColumnChooser: "true",
    allowGrouping: "false",
    allowExcelExport: "true",
    allowPdfExport: "true",
    allowReordering: "true",
    allowResizing: "true"
  }
};
