/* eslint-disable prettier/prettier */
<template>
  <div>
    <v-btn
      dark
      absolute
      fab
      bottom
      right
      class="accent black--text"
      @click="showDialog = true"
      style="position: fixed; bottom: 25px; right: 25px"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-toolbar elevation="5">
      <v-badge
        :content="table_data.length"
        :value="table_data.length"
        color="red"
        >Pillar Numbers</v-badge
      >
      <v-btn icon @click="getTableData(tableName)">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn
        v-if="role == 'Admin'"
        class="ml-1"
        raised
        tile
        @click="show_dialog_request = true"
        >QA/QC
      </v-btn>
      <v-btn
        v-else
        disabled
        class="ml-1"
        raised
        tile
        @click="show_dialog_request = true"
        >QA/QC
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn
        class="primary white--text ml-1"
        :disabled="!valid"
        raised
        tile
        @click="d"
        >Search
      </v-btn> -->

      <!-- <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>-->
    </v-toolbar>
    <v-progress-linear
      indeterminate
      color="pink"
      height="4"
      v-show="showProgress"
    ></v-progress-linear>
    <div>
      <ejs-grid
        style="border-style: none"
        width="100%"
        ref="grid"
        gridLines="horizontal"
        :dataSource="table_data"
        :childGrid='childGrid'         
        :detailDataBound='onDetailDataBound'        
        :rowHeight="40"
        :allowPaging="true"
        :allowSorting="true"
        :pageSettings="pageSettings"
        :selectionSettings="selectionSettings"
        :allowFiltering="true"
        :filterSettings="filterOptions"
        :actionComplete="actionComplete"
        :actionBegin="actionBegin"
        :allowSelection="true"
        :contextMenuItems="contextMenuItems"
        :editSettings="editSettings"
        :showColumnMenu="true"
        :toolbar="toolbar"
        :showColumnChooser="true"
        :allowGrouping="false"
        :allowExcelExport="false"
        :allowPdfExport="false"
        :allowReordering="true"
        :allowResizing="true"
        :rowSelected="onRowSelected"
        :toolbarClick="toolbarClick"
      >
        <e-columns>
          <e-column
            type="checkbox"
            :allowFiltering="false"
            :allowSorting="false"
            :allowEditing="false"
            width="40"
          ></e-column>
          <e-column
            field="createdBy"
            headerText="Created By"
            allowEditing="false"
            width="100"
            isPrimaryKey="true"
            textAlign="Left">
            </e-column>
            
          <e-column
            field="id"
            headerText="id"
            allowEditing="false"
            width="100"
            isPrimaryKey="true"
            textAlign="Left"
            type="int"
          >
          </e-column>
          <e-column
            field="surveyor"
            headerText="Surveyor"
            allowEditing="false"
            width="100"
            textAlign="Left"
            type="int"
          >
          </e-column>
          <e-column
            field="issuedDate"
            headerText="Date"
            allowEditing="false"
            width="150"
            textAlign="Left"
            type="int"
          >
          </e-column>
          <e-column
            field="quantity"
            type="int"
            headerText="No of Pillars"
            allowEditing="false"
            width="150"
          ></e-column>
          <e-column
            field="noOfPlans"
            headerText="Survey Plans"
            allowEditing="false"
            width="150"
            type="int"
            textAlign="Left"
          ></e-column>
          <e-column
            field="beaconNumber"
            headerText="Beacon Number"
            allowEditing="false"
            width="150"
            textAlign="Right"
          ></e-column>
         
          <e-column
            field="surveyorSuffix"
            headerText="Suffix"
            allowEditing="false"
            width="150"
            textAlign="Left"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>

    <v-dialog v-model="dialog" max-width="550px">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          Edit Records
          <v-icon color="red">mdi-arrow-right</v-icon>
          <v-icon x-small>mdi-file</v-icon>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text> <v-icon>cancel</v-icon>Cancel </v-btn>
        <v-btn text> <v-icon>done</v-icon>Save </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text class="black--text" style="height: 300px; overflow: auto">
          <v-container>
            <v-text-field
              v-for="(item, value) in selection"
              type="text"
              :key="value"
              :v-model="selection"
              :label="value"
            >
              <v-icon slot="prepend" color small>mdi-pencil</v-icon>
            </v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveEdits(selection)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showProgress" hide-overlay persistent width="400">
      <v-card color="warn">
        <v-card-text class="white pa-2">
          Connecting to your database, please wait...
          <v-progress-linear
            indeterminate
            color="primary"
            class="ma-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="
              showDialog = true;
              alert.alertDialog = false;
            "
            >Retry</v-btn
          >
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      content-class="black--text"
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.bgcolor"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
    >
      {{ alert.text }}
    </v-snackbar>
    <v-dialog persistent v-model="show_dialog_request" max-width="900px">
      <v-card>
        <v-row class="mx-2">
          <v-col col-md-7 col-xs-12>
            <div class="text-left" style="padding: 10px; magin-bottom: 10px">
              <v-toolbar-title>MDS Value Correction</v-toolbar-title>
              <small>
                Enter the receipt no of the job request with wrongly inputed
                MDS</small
              >
            </div>
          </v-col>
          <v-col col-md-3 col-xs-8 class="mt-4">
            <v-text-field
              v-model="search_text"
              prepend-inner-icon="search"
              dense
              outlined
              label="Search by Receipt No."
              hint="Enter the invoive no"
            >
            </v-text-field>
          </v-col>
          <v-col col-md-2 col-xs-4 class="mt-4">
            <v-btn
              class="primary white--text"
              raised
              tile
              @click="findRequest()"
              >Search
            </v-btn>
            <v-btn class="ml-1" raised tile @click="show_dialog_request = false"
              >Cancel
            </v-btn>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
        </v-row>
        <v-divider></v-divider>
        <v-stepper v-show="request.surcon" v-model="request_page" alt-labels>
          <v-stepper-header v-show="false">
            <v-stepper-step step="1" :complete="request_page > 1">
              Request
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="request_page > 2">
              Preview
            </v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form v-model="valid">
                <v-row class="mt-2">
                  <v-col col-md-6 col-xs-12>
                    <v-text-field
                      v-model="request.name"
                      prepend-inner-icon="mdi-certificate-outline"
                      dense
                      disabled
                      label="Surveyor*"
                      hint="e.g. Surveyor's name"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      v-model="request.job_type"
                      prepend-inner-icon="pencil"
                      dense
                      disabled
                      label="Job Type*"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      v-model="request.surcon"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense
                      disabled
                      label="Surcon Number*"
                      hint="e.g. 1022"
                      :rules="[rules.required]"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col-lg-5 col-md-5 col-sm-5 col-xs-12>
                    <v-text-field
                      v-model="request.planno"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense
                      disabled
                      label="Plan no.*"
                      :rules="[rules.required]"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-lg-2 col-md-2 col-xs-12>
                    <v-text-field
                      type="Number"
                      v-model="request.pillars"
                      label="No. of pillars"
                      prepend-inner-icon="mdi-numeric"
                      dense
                      disabled
                      @change="computeCharges()"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-lg-2 col-md-3 col-xs-12>
                    <v-text-field
                      v-model="request.mds"
                      prepend-inner-icon="mdi-dollar"
                      dense
                      outlined
                      @change="calTotal()"
                      label="MDS Amount*"
                      hint="e.g. enter amount paid for MDS"
                      persistent-hint
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-lg-3 col-md-3 col-xs-12>
                    <v-text-field
                      dense
                      disabled
                      prepend-inner-icon="mdi-calendar-clock"
                      v-model="request.request_date"
                      label="Request Date*"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col-md-6 col-xs-12>
                    <v-text-field
                      v-model="request.appsn"
                      prepend-inner-icon="mdi-numeric"
                      dense
                      disabled
                      label="APPSN Amount*"
                      hint="amount for APPSN fees"
                      persistent-hint
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-6 col-xs-12>
                    <v-text-field
                      v-model="request.ssce"
                      prepend-inner-icon="mdi-numeric"
                      dense
                      disabled
                      label="SURCON Amount*"
                      hint="amount for SSCE fees"
                      persistent-hint
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-6 col-xs-12>
                    <v-text-field
                      v-model="request.pdf"
                      prepend-inner-icon="mdi-numeric"
                      dense
                      disabled
                      label="Secretariat Dev.Fund*"
                      hint="amount for sec. fund"
                      persistent-hint
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <h4
                  v-show="amountInWords"
                  class="font-weight-normal red--text mt-1"
                >
                  <span class="black--text">Grand Total:</span
                  >{{ " N" + request.total }} ({{
                    amountInWords + " naira only"
                  }})
                </h4>
                <v-divider class="mt-5"></v-divider>
                <v-card-actions>
                  <v-btn raised color="warn" tile dark @click="resetRequest()"
                    >Cancel</v-btn
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="!valid"
                    class="primary white--text"
                    raised
                    tile
                    @click="request_page = 2"
                    >Continue
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card elevation="0" class="text-center">
                <div class="row">
                  <div class="col-md-12 round-corner2">
                    <md-card-area>
                      <md-card-content id="printSection">
                        <div
                          style="background: url(./img/icons/logo.png) repeat"
                        >
                          <div style="opacity: 0.95; background-color: white">
                            <div class="invoice-box">
                              <img
                                height="200px"
                                width="200px"
                                style="
                                  opacity: 0.25;
                                  position: absolute;
                                  top: 45%;
                                  left: 40%;
                                "
                                src="../assets/logo.png"
                              />
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                style="
                                  width: 100%;
                                  line-height: inherit;
                                  text-align: left;
                                "
                              >
                                <tr class="top">
                                  <td colspan="3">
                                    <table>
                                      <tr>
                                        <td class="title">
                                          <img
                                            src="../assets/logo.png"
                                            width="80px"
                                          />
                                        </td>
                                        <td></td>
                                        <td>
                                          <span
                                            style="
                                              font-size: 18px;
                                              font-weight: bold;
                                            "
                                            >Receipt No:
                                            {{ request.invoice_no }}</span
                                          >
                                          <br />
                                          Created:
                                          {{ new Date().toLocaleString() }}
                                          <br />
                                          Status: <b>{{ payment_status }}</b>
                                          <br />
                                          Quarter: <b>{{ request.quarter }}</b>
                                          <br />Plan Number(s):
                                          <b>{{ request.planno }}</b>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr class="information">
                                  <td colspan="3">
                                    <table>
                                      <tr>
                                        <td>
                                          Nigeria Institution of Surveyors
                                          <br />Block H, Plot B, Ikeja CBD,
                                          Alausa, Lagos State <br />Phone:
                                          (+234) 815 272 4484
                                        </td>
                                        <td></td>
                                        <td>
                                          <h3>Surv. {{ request.name }}</h3>
                                          <br />
                                          {{ request.phone }}
                                          <br />
                                          {{ request.company }}
                                          <br />
                                          {{ request.email }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <table>
                                      <tr class="heading">
                                        <td>Beacons</td>
                                        <td>Job Type</td>
                                        <td>Amount in words</td>
                                      </tr>
                                      <tr>
                                        <td>{{ request.pillars }}</td>
                                        <td>{{ request.job_type }}</td>
                                        <td>
                                          {{
                                            numToWords(request.total) +
                                            "naira only"
                                          }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <!-- <hr />   -->

                                <tr>
                                  <table>
                                    <tr class="heading">
                                      <td>Payment Break-down</td>
                                      <td>Unit Cost</td>
                                      <td>Total(N)</td>
                                    </tr>
                                    <tr class="item">
                                      <td>1.MDS</td>
                                      <td>
                                        {{ getNumberWithCommas(request.mds) }}
                                      </td>
                                      <td style="font-size: 15px">
                                        {{ getNumberWithCommas(request.mds) }}
                                      </td>
                                    </tr>

                                    <tr class="item">
                                      <td>2.SSCE Beacon Fees</td>
                                      <td>600</td>
                                      <td>
                                        {{ getNumberWithCommas(request.ssce) }}
                                      </td>
                                    </tr>

                                    <tr class="item">
                                      <td>3.Sect. Dev. Fund</td>
                                      <td>400</td>
                                      <td>
                                        {{ getNumberWithCommas(request.pdf) }}
                                      </td>
                                    </tr>
                                    <tr class="item">
                                      <td>4. APPSN Fees</td>
                                      <td>100</td>
                                      <td>
                                        {{ getNumberWithCommas(request.appsn) }}
                                      </td>
                                    </tr>

                                    <tr class="total" style="font-size: 16px">
                                      <td></td>
                                      <td class="strong">Total:</td>
                                      <td style="font-size: 20px">
                                        <b>{{
                                          getNumberWithCommas(request.total)
                                        }}</b>
                                      </td>
                                    </tr>
                                  </table>
                                </tr>
                                <hr />
                                <br />

                                <tr>
                                  <table>
                                    <tr
                                      class="total"
                                      style="font-size: 16px; text-align: right"
                                    >
                                      <hr />
                                      Signature & Date;
                                      {{
                                        "Updated by " + userName
                                      }}
                                    </tr>
                                    <tr>
                                      <br />
                                      <div
                                        id="qrcode"
                                        style="
                                          margin: auto;
                                          padding: 5px;
                                          border-width: 5px;
                                          width: 180px;
                                          height: 180px;
                                          border: solid, thick;
                                          border-color: black;
                                        "
                                      >
                                        <div id="qrcode"></div>
                                      </div>
                                    </tr>
                                  </table>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </md-card-content>
                    </md-card-area>
                  </div>
                </div>
              </v-card>
              <v-divider class="mt-2"></v-divider>
              <v-card-actions>
                <v-btn raised color="warn" tile @click="request_page = 1"
                  >Previous</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  raised
                  color="warn"
                  :disabled="submitted"
                  tile
                  dark
                  @click="resetRequest()"
                  >Cancel</v-btn
                >
                <v-btn
                  :disabled="submitted"
                  color="primary"
                  tile
                  raised
                  @click="updateRequest()"
                >
                  Update Request
                </v-btn>
                <v-btn
                  :disabled="!submitted"
                  color="primary"
                  tile
                  raised
                  @click="printInvoice()"
                >
                  Print
                </v-btn>
                <v-btn
                  :disabled="!submitted"
                  color="primary lighten-2"
                  tile
                  raised
                  @click="resetRequest()"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  GridPlugin,
  Filter,
  Page,
  Selection,
  Sort,
  Resize,
  ColumnMenu,
  PdfExport,
  ExcelExport,
  Toolbar,
  Reorder,
  Group,
  ColumnChooser,
  ContextMenu,
  Edit,
  CommandColumn,
  ForeignKey,
  Freeze,
  DetailRow
} from "@syncfusion/ej2-vue-grids";
import {DataManager,Query} from '@syncfusion/ej2-data';
import Api from "../app.settings.network";
import AppSettings from "../app.settings";
import VueHtmlToPaper from "vue-html-to-paper";
Vue.use(GridPlugin);

const apiUrl = AppSettings.API_ENDPOINT;
export default Vue.extend({
  props: {},
  data: () => {
    return {
      childGrid: {
        columns: [
          //  {type:"checkbox",
          //   allowFiltering:"false",
          //   allowSorting:"false",
          //   allowEditing:"false",
          //   width:"40"},
          { field: 'id', headerText: 'Id', textAlign: 'Left', width: 20 },
            
            { field: 'planNumber', headerText: 'Plan Number', textAlign: 'Left', width: 80 },
            { field: 'lga', headerText: 'LGA', width: 50, textAlign: 'Left' },
            { field: 'planTitle', headerText: 'Plan Title', textAlign: 'Left', width: 150 },
            { field: 'size', headerText: 'Size', textAlign: 'Centre', width: 50} ,
            { field: 'status', headerText:'Returned Status', textAlign: 'Left', width: 50 }    
        ]
      },
      submitted: false,
      show_dialog_request: false,
      request_page: 1,
      month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      search: {
        date_menu1: false,
        date_menu2: false,
        surcon: "",
        year: "",
        quarter: "",
        month: "",
        day: "",
        start_date: "",
        end_date: "",
      },
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      tab: null,
      active_surveyor: {
        name: "",
        surcon: 2849,
        cadre: null,
      },
      request: {
        name: "",
        surcon: "",
        job_type: "",
        surcon: 0,
        planno: "",
        mds: null,
        appsn: 0,
        ssce: 0,
        pdf: 0,
        pillars: "",
        request_date: "",
        total: "",
      },
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Database Conection",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 4000,
        x: "right",
        y: "bottom",
      },
      dialog: false,
      showProgress: true,
      selRecord: {},
      height: "100%",
      allowPaging: true,
      pageSettings: {
        pageSizes: [5, 8, 10, 50, 100, 200, 500],
        pageSize: 8,
        pageCount: 10,
      },
      serverPageSettings: {
        size: 500,
        skip: 0,
        pageno: 1,
      },
      filterOptions: {
        type: "Menu",
      },
      filter: {
        type: "CheckBox",
      },
      selectionSettings: {
        persistSelection: true,
        type: "Multiple",
        checkboxOnly: true,
      },
      toolbar: [
        "Update",
        "Cancel",
        "Search",
        "ColumnChooser",
        "ExcelExport",
        "PdfExport",
        "CsvExport",
      ],
      contextMenuItems: [
        "AutoFit",
        "AutoFitAll",
        "SortAscending",
        "SortDescending",
        "Copy",
        "Edit",
        "Delete",
        "Save",
        "Cancel",
        // "PdfExport",
        // "ExcelExport",
        // "CsvExport",
        "FirstPage",
        "PrevPage",
        "LastPage",
        "NextPage",
      ],
      editSettings: {
        // allowEditing: true,
        // allowAdding: true,
        // allowDeleting: true,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        mode: "Dialog",
      },
      editOptions: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        mode: "Dialog", //"Normal" 'Batch'
      },
      commands: [
        {
          type: "Edit",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-edit e-icons",
          },
        },
        {
          type: "Delete",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-delete e-icons",
          },
        },
        {
          type: "Save",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-update e-icons",
          },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
      table1: {
        tableName: "",
        settings: {},
        primaryKey: "",
        rows: [],
        columns: [],
        clientColumns: [],
      },
      tableName: "",
      tabledata1: [],
      table_data: [],
      search_text: "",
    };
  },
  filters: {
    subStr: function (value) {
      if (value !== undefined) {
        return "https://adamscollege.org" + value.substring(2);
      }
    },
  },
  computed: {
    role: function () {
      const role = localStorage.getItem("role");
      return role;
    },
    connection: function () {
      return this.$store.getters.getConnection;
    },
    userName: function () {
      return this.$store.getters.getUserName;
    },
    members: function () {
      return this.$store.getters.getMembers;
    },
    tables() {
      let req = this.$store.getters.getTables;
      return req;
    },
    progressStatus() {
      return this.$store.getters.getProgressStatus;
    },
  },
  methods: {
    onDetailDataBound(args) {
      // var orderData = data;
      // var empIdValue = args.childGrid.parentDetails.parentRowData.EmployeeID;
      // var matchedData = new DataManager(data).executeLocal(
      //   new Query().where("EmployeeID", "equal", empIdValue, true)
      // );
      const plans = args.childGrid.parentDetails.parentRowData.backlogPlans;
      plans.forEach((item, i) => {
        return item.id = i + 1
      })   
      //console.log(plans)
      args.childGrid.query = new Query();
      args.childGrid.dataSource = plans;
    },
    getTableData: function () {
      this.tableName = "Backlogs";
      const vm = this;
      this.showProgress = true;
      Api()
        .get("api/backlogs")
        .then((response) => {
          this.showProgress = false;
          //console.log(response.data);
          return (this.table_data = response.data);
        })
        .catch(function (ex) {
          //console.log(ex.response.data);
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data)
          );
        });
    },
    //left=100,top=100,width=720,height=320,
    printInvoice() {
      const options = {
        name: "_blank",
        specs: ["titlebar=yes", "scrollbars=yes"],
        styles: ["css/print.css"],
        //"timeout": 1000,
        autoClose: true,
        windowTitle: this.active_surveyor.name,
      };
      Vue.use(VueHtmlToPaper, options);

      this.$htmlToPaper("printSection");
    },
    updateRequest: function () {
      const vm = this;
      this.showProgress = true;
      const invoice_no = this.request.invoice_no;
      const updates = { mds: this.request.mds, total: this.request.total };
      Api()
        .post("api/nis/pillar_requests/invoice_no/" + invoice_no, updates)
        .then((response) => {
          this.submitted = true;
          this.alert.text = "Request was updated successfully";
          this.showProgress = false;
          this.alert.alertDialog = false;
          this.alert.snackbar = true;
          this.alert.bgcolor = "primary";
          this.page = 2;
          this.updateTransactionRequest();
        })
        .catch(function (ex) {
          return (
            (vm.submitted = false),
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    updateTransactionRequest: function () {
      const vm = this;
      this.showProgress = true;
      const invoice_no = this.request.invoice_no;
      const updates = { amount: this.request.mds };
      Api()
        .post("api/nis/account_transactions/invoice_no/" + invoice_no, updates)
        .then((response) => {
          this.submitted = true;
          this.alert.text =
            "Transaction of MDS request was updated successfully";
          this.showProgress = false;
          this.alert.alertDialog = true;
          this.alert.snackbar = true;
          this.alert.bgcolor = "primary";
          this.page = 2;
          // this.table_data.filter((x) => {
          //   return x.invoice_no == invoice_no;
          // })[0].mds = this.request.mds;
        })
        .catch(function (ex) {
          return (
            (vm.submitted = false),
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    numToWords(val) {
      if (val != null) {
        let words = numToWords(Number(val));
        return words;
      }
    },
    getNumberWithCommas(x) {
      if (x != null) {
        //alert(x)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
      }
    },
    calTotal() {
      const total =
        Number(this.request.mds) +
        Number(this.request.ssce) +
        Number(this.request.appsn) +
        Number(this.request.pdf);
      let words = numToWords(total);
      return (this.request.total = total), (this.amountInWords = words);
    },
    findRequest() {
      if (this.search_text.length > 4) {
        //alert(this.search_text);
        const search = this.search_text.trim();
        let request = this.table_data.filter((x) => {
          return x.invoice_no == search;
        })[0];
        //alert(JSON.stringify(surveyor))
        if (request) {
          this.request = request;
          let member = this.members.find((m) => m.surcon == request.surcon);
          this.request.name = member.name;

          var qrData =
            "https://nisserver:5001/?receipt_no=" + this.request.invoice_no;
          var qrcode = new QRCode(document.getElementById("qrcode"), {
            width: 160,
            height: 160,
            colorDark: "green",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
          qrcode.makeCode(qrData);
        }
      }
    },
    resetRequest() {
      this.request = {};
      this.show_dialog_request = false;
      document.getElementById("qrcode").innerHTML = "";
    },
    
    UpdateJSON(jsonArray, item) {
      for (var i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].RegistrationID == item.RegistrationID) {
          jsonArray[i] = item;
          return jsonArray;
        }
      }
    },
    update(data) {
      const vm = this;
      this.showProgress = true;
      //alert(JSON.stringify(data));
      try {
        Api()
          .put(apiUrl + "api/table/update/" + data.pKeyValue, data)
          .then((response) => {
            vm.alert.text =
              "Record with " +
              data.primaryKey +
              " = " +
              data.pKeyValue +
              " was updated successfully";
            vm.alert.title = "Table Record Update";
            vm.showProgress = false;
            vm.alert.bgcolor = "accent";
            //vm.alert.alertDialog = true;
            vm.alert.snackbar = true;
            vm.showDialog = false;
            return response.data.message;
          })
          .catch(function (ex) {
            if (ex.response != undefined) {
              vm.alert.title = "Record Update Failed";
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"),
          (this.alert.text = e.message),
          (this.alert.title = "Table Records")
        );
      }
    },

    toolbarClick: function (args) {
      switch (args.item.text) {
        case "PDF Export":
          this.$refs.grid.pdfExport();
          break;
        case "Excel Export":
          this.$refs.grid.excelExport();
          break;
        case "CSV Export":
          this.$refs.grid.csvExport();
          break;
      }
    },
    exportToPDF() {
      this.$refs.grid.pdfExport();
    },
    exportToExcel() {
      this.$refs.grid.excelExport();
    },
    pushSelectedRow(item) {
      const payload = { record: item, tablename: this.tableoptions.tablename };
      this.$store.commit("setSelectedRow", payload);
    },
    onRowSelected: function (args) {
      //alert(JSON.stringify(args.data));
      if (
        args.requestType !== "beginEdit" ||
        args.requestType !== "add" ||
        args.requestType !== "delete"
      ) {
        const record = args.data;
        //this.dialog = true;
        //let columns = this.table.columns;
        //alert(JSON.stringify(record));
        let fields = [];
        let i = 0;
        let f;
        for (const key in record) {
          if (key == this.table.primaryKey) {
            //alert(key);
            f = {
              field: key,
              value: record[key],
              primaryKey: true,
              readOnly: true,
              disabled: true,
              type: "text",
              icon: "mdi-key",
            };
            fields[i] = f;
          } else {
            f = {
              field: key,
              value: record[key],
              primaryKey: false,
              readOnly: false,
              disabled: false,
              //required : isNullable,
              icon: "mdi-pencil",
              type: "text",
              editType: "datepickeredit or dropdownedit or numericedit",
            };
            fields[i] = f;
          }
          i++;
        }
        this.selRecord = fields;
        this.selection = args.data;
      }
    },
    actionComplete(args) {
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        let dialog = args.dialog;
        if (window.innerWidth < 400) {
          dialog.height = 80 + "%";
          dialog.width = 85 + "%";
        } else {
          dialog.height = 70 + "%";
          dialog.width = 60 + "%";
        }

        // change the header of the dialog
        dialog.header =
          args.requestType === "beginEdit"
            ? "Record of " + args.rowData[this.table.primaryKey]
            : "New " + this.table.tableName;
      }
    },

    getTableRecords() {
      this.showProgress = true;
      const vm = this;
      try {
        Api()
          .put(
            apiUrl + "api/table/" + this.connection.tableName,
            this.connection
          )
          .then((response) => {
            this.$store.commit("setTable", response.data);
            return (this.showProgress = false), (this.showDialog = false);
          })
          .catch(function (ex) {
            if (ex.response != undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"),
          (this.alert.text = e.message),
          (this.alert.title = "Table Records")
        );
      }
    },
    encriptPass(plainPassword) {
      const a = this.CryptoJS.AES.encrypt(
        plainPassword,
        localStorage.getItem("userName")
      ).toString();
      //alert(a);
      return a;
    },
    decriptPass(encriptedPassword) {
      const a = this.CryptoJS.AES.decrypt(
        encriptedPassword,
        localStorage.getItem("userName")
      ).toString(this.CryptoJS.enc.Utf8);
      //alert(a);
      return a;
    },
  },
  watch: {
    table() {
      this.sheet = false;
      //alert(this.connection.primaryKey)
      if (!this.connection.primaryKey) {
        //alert(this.editSettings.allowEditing)
        return (this.editSettings.allowEditing = false);
      }
    },
    selectedQuery(query) {
      return (
        (this.code = query.statement), (this.queryName = query.description)
      );
    },
    queryName(queryName) {
      if (queryName.length > 0 && this.code.length > 5) {
        return (this.isQueryNameExist = true);
      } else {
        return (this.isQueryNameExist = false);
      }
    },
  },
  provide: {
    grid: [
      Filter,
      Page,
      Selection,
      Sort,
      Resize,
      ColumnMenu,
      Reorder,
      PdfExport,
      ExcelExport,
      Toolbar,
      Group,
      ContextMenu,
      Edit,
      CommandColumn,
      ColumnChooser,
      ForeignKey,
      Freeze,
      DetailRow
    ],
  },
  mounted() {
    this.editor.focus();
    //console.log("this is current editor object", this.editor);
  },
  created() {
    this.showProgress = false;
    this.showDialog = false;
    //this.$store.commit("loadSqlQueries")
    this.getTableData();
  },
});
const arr = (x) => Array.from(x);
const num = (x) => Number(x) || 0;
const str = (x) => String(x);
const isEmpty = (xs) => xs.length === 0;
const take = (n) => (xs) => xs.slice(0, n);
const drop = (n) => (xs) => xs.slice(n);
const reverse = (xs) => xs.slice(0).reverse();
const comp = (f) => (g) => (x) => f(g(x));
const not = (x) => !x;
const chunk = (n) => (xs) =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

// numToWords :: (Number a, String a) => a -> String
let numToWords = (n) => {
  let a = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  let b = [
    "",
    "",
    "and twenty",
    "and thirty",
    "and forty",
    "and fifty",
    "and sixty",
    "and seventy",
    "and eighty",
    "and ninety",
  ];

  let g = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
  ];

  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones, tens, huns]) => {
    return [
      num(huns) === 0 ? "" : a[huns] + " hundred ",
      num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
      a[tens + ones] || a[ones],
    ].join("");
  };

  let thousand = (group, i) => (group === "" ? group : `${group} ${g[i]}`);

  if (typeof n === "number") return numToWords(String(n));
  else if (n === "0") return "zero";
  else
    return comp(chunk(3))(reverse)(arr(n))
      .map(makeGroup)
      .map(thousand)
      .filter(comp(not)(isEmpty))
      .reverse()
      .join(", ");
};
</script>
<style scoped>
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
.e-grid .e-altrow {
  background-color: whitesmoke;
}

</style>
